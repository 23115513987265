import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Button } from "./ui"

const Popup = () => {
    const [scrolled, changeState] = useState(false)

    useEffect(() => {
        const onScroll = () => {
            if (document.documentElement.scrollTop > 80 && !scrolled) {
                changeState(true)
            } else if (document.documentElement.scrollTop <= 80 && scrolled)
                changeState(false)
        }

        window.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [scrolled])

    return (
        <React.Fragment>
            <div
                className={`sm:hidden duration-300 transition-all flex justify-end items-center z-50 fixed w-full bottom-0 left-0 nav  ${
                    scrolled ? "" : ""
                }`}
            >
                <div className="pr-3">
                    <Link to="/contact">
                        <Button
                            title="新聞・配達に関するご連絡"
                            type="button"
                            // iconRight={<ArrowRight />}
                            aria-label="新聞・配達に関するご連絡"
                        />
                    </Link>
                </div>
                <div className="absolute line h-px left-0 bottom-0 bg-gradient-primary"></div>
            </div>
            {/* {navPlaceholder && (
                <div style={{ height: `${navbarHeight}px` }}></div>
            )} */}
        </React.Fragment>
    )
}

export default Popup
